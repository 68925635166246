var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[(_vm.alerts)?_c('v-alert',{attrs:{"color":"red lighten-1","type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.alerts)+" ")]):_vm._e(),(_vm.messages)?_c('v-alert',{attrs:{"color":"green lighten-1","type":"success","dense":""}},[_vm._v(" "+_vm._s(_vm.messages)+" ")]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('v-card',[_c('v-card-title',[_vm._v("找回密码/Recover Account")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('v-text-field',{attrs:{"counter":10,"rules":[
                  (v) => !!v || '',
                  (v) => (v && v.length <= 10) || '姓名过长/Name too long',
                ],"label":"姓名/Name","required":""},model:{value:(_vm.requestForm.name),callback:function ($$v) {_vm.$set(_vm.requestForm, "name", $$v)},expression:"requestForm.name"}}),_c('v-text-field',{attrs:{"rules":[
                  (v) => !!v || '',
                  (v) => /.+@.+\..+/.test(v) || '错误的电子邮箱/Wrong email address',
                ],"label":"电子邮箱/Email","required":""},model:{value:(_vm.requestForm.email),callback:function ($$v) {_vm.$set(_vm.requestForm, "email", $$v)},expression:"requestForm.email"}}),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":!_vm.requestForm.name || !_vm.requestForm.email,"color":"primary"}},[_vm._v(" 提交/Submit ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }