const axios = require('axios');
import router from '@/router'
import store from '@/store'
import usersService from '@/services'

const requests = axios.create({
    timeout: 60000 // request timeout
});


requests.interceptors.request.use(
    config => {
        const token = usersService.getToken();
        if (token == null) {
            return config
        }
        config.headers["Authorization"] = "Bearer " + token;
        return config;
    },
);

requests.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status == 401) {
            usersService.logout();
            store.commit("updateCurrentUser");
            router.push("/");
        } else if (error.response.status == 500) {
            alert("服务器错误，请重试。若多次重试失败，请联系系夏令营组委会/Server error, please retry. Contact us if failed multiple times.")
            throw error;
        } else {
            throw error;
        }
    }
)


export default requests;
